<template>
  <div class="home">
    <h2>Conditional License Tracking</h2>

    <v-snackbar
      top
      multi-line
      :color="snackbar.color"
      v-model="snackbar.visible"
    >
      {{this.snackbar.text}}
    </v-snackbar>

    <v-row dense class="mt-3">
      <v-col cols="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Project Number"
          :value="prefix"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Facility Name"
          :value="evaluation.providerName"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Survey Type ID"
          :value="evaluation.surveyType"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Survey Type"
          :value="evaluation.surveyTypeName"
        />
      </v-col>
    </v-row>

    <h3>Conditional Licenses</h3>

    <div v-if="licenses.length > 0">
      <v-row dense class="mt-3">
        <v-col cols="1">
          <p class="header">Visit</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Duration</p>
        </v-col>
        <v-col cols="2">
          <p class="header">Enf Ltr Sent</p>
        </v-col>
        <v-col cols="2">
          <p class="header">RN Consultant</p>
        </v-col>
        <v-col cols="2">
          <p class="header">No New Subst Maltx</p>
        </v-col>
        <v-col cols="2">
          <p class="header">No New Admissions</p>
        </v-col>
        <v-col cols="2">
          <p class="header">Other</p>
        </v-col>
      </v-row>

      <ConditionalLicense
        v-for="license in licenses"
        :key="license.id"
        :license="license"
        :pendingStatus="pendingLicenses.includes(license.id)"
        @togglePending="togglePending"
      />
    </div>

    <div v-if="licenses.length === 0">
      <h3 class="mt-3">No conditional licenses available for this evaluation</h3>
    </div>

    <v-row class="form-button">
      <v-col class="form-button">
        <v-btn @click="addLicense" color="primary">
          Add
        </v-btn>
      </v-col>
      <v-col class="form-button" >
        <v-btn @click="saveLicenses" color="primary">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ConditionalLicense from "./ConditionalLicense.vue";
  import {evalApi} from "@/plugins/axios";

  export default {
    props: {
      evaluation: Object
    },

    components: {ConditionalLicense},

    data() {
      return {
        licenses: [],
        snackbar: {
          visible: false,
          color: "#2a602c",
          text: ""
        },
        pendingLicenses: []
      }
    },

    created() {
      this.loadLicenses()
    },

    mounted() {
      window.scrollTo(0, 0)
    },

    computed: {
      prefix() {
        return this.evaluation.evaluationNumber.split('-')[0]
      },
      evalNums() {
        let evalNumList = []
        for (let license of this.licenses) {
          if (!evalNumList.includes(license.evaluationNumber)) {
            evalNumList.push(license.evaluationNumber)
          }
        }
        return evalNumList;
      }
    },

    methods: {
      loadLicenses() {
        evalApi.get(`conditionalLicense/getLicenses/` + this.prefix)
          .then(res => {
            this.licenses = res.data

            for (const license of this.licenses) {
              for (const key of Object.keys(license)) {
                if (key !== 'id' && key !== 'evaluationNumber' && key !== 'duration') {
                  license[key] = this.formatDate(license[key])
                }
              }
            }
          })
          .catch(error => console.log(error.message))
      },

      addLicense() {
        this.licenses.push({
          id: crypto.randomUUID(),
          evaluationNumber: this.evaluation.evaluationNumber,
          duration: null,
          enfLtrSent: null,
          consultStartDt: null, consultEndDt: null,
          subStartDt: null, subEndDt: null,
          adminStartDt: null, adminEndDt: null,
          otherStartDt: null, otherEndDt: null,
          comment: ""
        })
      },

      async saveLicenses() {
        try {
          for (let evalNum of this.evalNums) {
            let res = await evalApi.get(`/evaluationsImport/evalNumExists/` + evalNum)
            if (!res.data) {
              this.snackbar.color = "#b60000"
              this.snackbar.text = "Evaluation " + evalNum + " does not exist."
              this.snackbar.visible = true
              return
            }
          }

          // Delete all pending licenses
          this.licenses = this.licenses.filter(license => !this.pendingLicenses.includes(license.id))
          await evalApi.post(`/conditionalLicense/delete`, this.pendingLicenses)

          // Save remaining licenses
          await evalApi.post(`/conditionalLicense/save`, this.licenses)

          this.snackbar.color = "#2a602c"
          this.snackbar.text = "Your conditional licenses have been saved."
          this.snackbar.visible = true
        } catch(error) {
          this.snackbar.color = "#b60000"
          this.snackbar.text = "An error occurred during saving process: " + error
          this.snackbar.visible = true
        }
      },

      formatDate(date) {
        return date != null ? new Date(date).toISOString().split('T')[0] : null
      },

      togglePending(id) {
        if (this.pendingLicenses.includes(id)) {
          this.pendingLicenses = this.pendingLicenses.filter(arrayId => arrayId !== id)
        } else this.pendingLicenses.push(id)
      }
    }
  }
</script>

<style>
  .text-black input {
    color: black !important;
  }

  .header {
    margin-bottom: 0 !important;
    font-size: 0.9em;
  }

  .form-button.row {
    justify-content: center;
    margin-top: 12px;
  }

  .form-button.col {
    flex-grow: 0 !important;
  }
</style>