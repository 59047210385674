<template>
  <div class="home">
    <h2>Penalty Assessment Details</h2>

    <v-snackbar
      top
      multi-line
      :color="snackbar.color"
      v-model="snackbar.visible"
    >
      {{this.snackbar.text}}
    </v-snackbar>

    <v-row dense class="mt-3">
      <v-col cols="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Project Number"
          :value="prefix"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="Facility Name"
          :value="evaluation.providerName"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          rounded
          readonly
          class="text-black"
          label="County"
          :value="evaluation.providerCounty"
        />
      </v-col>
    </v-row>

    <h3>Penalty Assessments</h3>

    <div v-if="assessments.length > 0">
      <v-row dense class="mt-3">
        <v-col cols="1">
          <p class="header">Visit</p>
        </v-col>
        <v-col cols="1">
          <p class="header">HFID / Provider #</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Invoice #</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Tag #</p>
        </v-col>
        <v-col cols="2">
          <p class="header">Description</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Statute</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Part</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Subpart</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Item</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Subitem</p>
        </v-col>
        <v-col cols="1">
          <p class="header">Assessment</p>
        </v-col>
      </v-row>

      <PenaltyAssessment
        v-for="assessment in assessments"
        :key="assessment.id"
        :assessment="assessment"
        :pendingStatus="pendingAssessments.includes(assessment.id)"
        @togglePending="togglePending"
      />
    </div>

    <div v-if="assessments.length === 0">
      <h3 class="mt-3">No penalty assessments available for this evaluation</h3>
    </div>

    <v-row class="form-button">
      <v-col class="form-button">
        <v-btn @click="addAssessment" color="primary">
          Add
        </v-btn>
      </v-col>
      <v-col class="form-button" >
        <v-btn @click="saveAssessments" color="primary">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import PenaltyAssessment from "./PenaltyAssessment";
  import {evalApi} from "@/plugins/axios";

  export default {
    props: {
      evaluation: Object
    },

    components: {PenaltyAssessment},

    data() {
      return {
        assessments: [],
        snackbar: {
          visible: false,
          color: "#2a602c",
          text: ""
        },
        pendingAssessments: []
      }
    },

    created() {
      this.loadAssessments()
    },

    mounted() {
      window.scrollTo(0, 0)
    },

    computed: {
      prefix() {
        return this.evaluation.evaluationNumber.split('-')[0]
      },
      evalNums() {
        let evalNumList = []
        for (let assessment of this.assessments) {
          if (!evalNumList.includes(assessment.evaluationNumber)) {
            evalNumList.push(assessment.evaluationNumber)
          }
        }
        return evalNumList;
      }
    },

    methods: {
      loadAssessments() {
        evalApi.get(`penaltyAssessment/getAssessments/` + this.prefix)
          .then(res => {
            this.assessments = res.data
          })
          .catch(error => console.log(error.message))
      },

      addAssessment() {
        this.assessments.push({
          id: crypto.randomUUID(),
          evaluationNumber: this.evaluation.evaluationNumber,
          providerNumber: null,
          invoiceNumber: null,
          tagNumber: null,
          description: null,
          statute: null,
          part: null, subpart: null,
          item: null, subitem: null,
          comment: null
        })
      },

      async saveAssessments() {
        try {
          for (let evalNum of this.evalNums) {
            let res = await evalApi.get(`/evaluationsImport/evalNumExists/` + evalNum)
            if (!res.data) {
              this.snackbar.color = "#b60000"
              this.snackbar.text = "Evaluation " + evalNum + " does not exist."
              this.snackbar.visible = true
              return
            }
          }

          // Delete all pending licenses
          this.assessments = this.assessments.filter(assessment => !this.pendingAssessments.includes(assessment.id))
          await evalApi.post(`/penaltyAssessment/delete`, this.pendingAssessments)

          // Save remaining licenses
          await evalApi.post(`/penaltyAssessment/save`, this.assessments)

          this.snackbar.color = "#2a602c"
          this.snackbar.text = "Your penalty assessments have been saved."
          this.snackbar.visible = true
        } catch(error) {
          this.snackbar.color = "#b60000"
          this.snackbar.text = "An error occurred during saving process: " + error
          this.snackbar.visible = true
        }
      },

      togglePending(id) {
        if (this.pendingAssessments.includes(id)) {
          this.pendingAssessments = this.pendingAssessments.filter(arrayId => arrayId !== id)
        } else this.pendingAssessments.push(id)
      }
    }
  }
</script>

<style>
  .text-black input {
    color: black !important;
  }

  .header {
    margin-bottom: 0 !important;
    font-size: 0.9em;
  }

  .form-button.row {
    justify-content: center;
    margin-top: 12px;
  }

  .form-button.col {
    flex-grow: 0 !important;
  }
</style>